<template>
    <!-- <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="약관" tabActive="개인정보처리방침" bg="/images/test.jpg">
                <template #title>약관</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last"> -->
            <terms-component code="privacy" />
        <!-- </page-section-primary>
    </app-primary> -->
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import TermsComponent from "@/components/client/terms/terms-component.vue";

export default {
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        TermsComponent,
    },
    props: {},
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.$nextTick(() => {});
        },
    },
};
</script>

<style lang="scss" scoped></style>
